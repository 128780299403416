@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(~react-responsive-ui/style.css);
@import url('https://use.typekit.net/hkw0dqq.css');

html,
body {
  height: 100%;
  width: 100%;
  position: fixed;
}
#chat-root {
  min-height: 100%;
  height: 100%;
}
.emoji:hover svg {
  fill: #f7c64b !important;
  stroke: #000 !important;
}
.emoji-mart-bar {
  display: none;
}
.ChatApp {
  height: 100%;
}

#chat-root {
  font-family: proxima-nova, sans-serif !important;
}
.messageList {
  max-height: 76vh;
}
.messageList,
.channelList {
  overflow-y: overlay !important;
}
.channelList {
  overflow: overlay !important;
}

.msg .msg-content a {
  color: #3b7e2e;
  word-break: break-word;
}

.msg .msg-content .isOwnPM a {
  color: #e4e7eb;
  text-decoration: underline;
  word-break: break-word;
}

.msg-parse {
  word-break: break-word;
}
.userChat {
  flex: 1 1 20%;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: 10px;
  scrollbar-color: #2b353a #e4e7eb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #2b353a;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.loader {
  border-top-color: #2b353a;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#react-select-2-listbox,
#react-select-2-listbox > * {
  z-index: 99999999999;
}

.oopsie-msg {
  position: absolute;
  top: auto;
  bottom: 3.3rem;
  opacity: 0.6;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #000;
  padding: 5px 15px 5px 15px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
  transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 0.1s 0.3s;
  transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s;
  max-height: 4rem;
  z-index: 30;
  background-color: white;
  color: black;
  opacity: 1;
  visibility: hidden;
}

@media only screen and (max-width: 500px) {
  .oopsie-msg {
    max-height: 5.5rem;
  }
}

.oopsie-msg-content {
  margin: 0;
}

.oopsie-Slide-up:hover .oopsie-msg {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: opacity 0.1s, -webkit-transform 0.4s;
  transition: opacity 0.1s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s, -webkit-transform 0.4s;
  visibility: visible;
}

.messageInputField[contenteditable]:empty::before {
  content: 'Write A Message';
  color: gray;
  margin-top: 20px;
}

/** Emoji Style **/
.action-picker .emoji-mart-scroll {
  height: 150px;
}

.editMessage .picker .emoji-mart-scroll {
  height: 200px;
}
.action-picker .emoji-mart,
.editMessage .picker .emoji-mart {
  width: auto !important;
}
.action-picker .emoji-mart .emoji-mart-emoji,
.editMessage .picker .emoji-mart .emoji-mart-emoji {
  padding: 2px;
}

.action-picker .emoji-mart-category .emoji-mart-emoji span {
  height: 20px !important;
  width: 20px !important;
}

.action-picker .emoji-mart-category-label span {
  font-size: 0.75rem !important;
}

.action-picker .emoji-mart-search input {
  font-size: 12px !important;
}

.action-picker .emoji-mart-search-icon {
  top: 5px;
}

.emoji-mart-scroll {
  display: flex;
  flex-direction: column;
}
.hasEmojis .emoji-mart-scroll .emoji-mart-category:last-of-type {
  order: -1;
}
.emoji-mart-category[aria-label='RagingBull'] .emoji-mart-emoji-custom span {
  width: 48px !important;
}

.mention {
  background-color: transparent;
  padding-left: 3px;
  padding-right: 3px;
  font-style: italic;
  /* font-weight: 700; */
}
.userIsMentioned {
  color: black;
}
.mention.userIsMentioned {
  background-color: white;
  /* background-color: transparent; */
}
.msg-wrap:hover .mention.userIsMentioned {
  /* background-color: white; */
  background-color: transparent;
}
.msg-wrap:hover .mention-pinned.userIsMentioned {
  background-color: white;
}
.isMentionedMML {
  color: rgba(59, 126, 46, 1);
}

.isMentionedRB {
  color: rgba(11, 96, 172, 1);
}
.pinned-message .isMentionedMML {
  color: white !important;
}
.pinned-message .userIsMentioned {
  background-color: transparent !important;
}

.mml .pinned-message a {
  color: #f2a716 !important;
}
.mml .pinned-message a:hover {
  color: #f2a716 !important;
  text-decoration: underline;
}
.raginbull .pinned-message a {
  color: rgb(74, 156, 228);
}
.mml .pinned-message a:hover {
  color: rgb(74, 156, 228);
  text-decoration: underline;
}

@media (hover: none) {
  .actionDots {
    visibility: visible !important;
  }
}

@media only screen and (max-width: 600px) {
  .newMessagePopup {
    left: auto !important;
    right: 23% !important;
  }
  .action-picker {
    max-width: 65% !important;
    right: 11px !important;
    left: auto !important;
  }

  .isInIframe.action-picker {
    right: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .isInIframe .emoji-mart .emoji-mart-scroll {
    max-height: 150px !important;
  }

  .isInIframeInput,
  .isInIframeInput .emoji-mart,
  .isInIframeInput .emoji-mart-scroll {
    width: 100% !important;
    max-width: none !important;
  }
  .isInIframeInput .emoji-mart-scroll {
    max-height: 150px;
  }
}

@media only screen and (min-width: 700px) {
  .action-picker {
    max-width: 200px !important;
    margin-right: 20px !important;
  }
}
.hoveringFile .file-details {
  display: flex;
  visibility: visible;
}
.fileUpload {
  min-height: 200px;
  max-width: 320px;
  max-height: 200px;
}
.filePreview {
  height: 200px;
  width: 320px;
}
.filePreviewModal {
  height: 120px;
  width: 100%;
}
.preview-img {
  min-height: 200px;
  max-height: 200px;
  object-fit: cover;
  object-position: center;
}

.img-container {
  max-width: 275px;
  max-height: 350px;
  width: auto;
  height: auto;
}
.img-wrap {
  width: 100%;
  height: 100%;
  max-width: 275px;
  max-height: 350px;
  display: flex;
}
.file-image {
  object-fit: cover;
  height: auto;
  box-sizing: border-box;
  display: block;
}
.image-download {
  opacity: 0;
  transition: 0.33s ease;
}
.msg-file-info {
  transition: 0.33s ease;
}
.img-container:hover .image-download {
  opacity: 1;
}
/* .msg-wrap {
  height: auto !important;
} */

.userChat .msg-wrap {
  max-width: 100% !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.load-3 .line:nth-last-child(1) {
  animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .line:nth-last-child(2) {
  animation: loadingC 0.6s 0.2s linear infinite;
  margin-left: 2px;
  margin-right: 2px;
}
.load-3 .line:nth-last-child(3) {
  animation: loadingC 0.6s 0.3s linear infinite;
}

.load-3 .line {
  display: inline-block;
  width: 2px;
  height: 2px;
  border-radius: 9999px;
  background-color: black;
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 3px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.msg-wrap {
  min-height: -webkit-fit-content;
}
.chatEmoji {
  display: inline-block;
}

.scroll-list div:first-of-type,
.scroll-list:first-child div {
  overscroll-behavior: contain;
}

@media screen and (min-width: 1024px) {
  .openMessagesSide,
  .openAdminSide {
    max-height: calc(100% - 56px);
  }
}

/* Notifications */
.Toastify__toast-theme--light.Toastify__toast--info {
  background-color: #dcbe4a;
  color: #151515;
}
.userTagAdminSingle {
  z-index: 999997 !important;
}
.userTagAdminSingle > div[class$='container'] {
  overflow-y: visible;
  z-index: 999998 !important;
}

.userTagAdminSingle > div[class$='menu'],
.userTagAdminSingle > div[class$='menu'] > * {
  z-index: 999999 !important;
}

.Toastify__toast-icon {
  align-self: flex-start;
  margin-top: 5px;
}
